import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { showMhsPojt, patchPojt, getPerusahaan } from "../../api";
import Loading from "../../components/Loading";
import { titleState } from "../../storages";
import { tokenState, userState } from "../../storages/auth";
import { getOrigin, saAlert, saConfirm } from "../../helpers";
// import Input from "../../components/Input";
// import Select from "../../components/Select";
// import Table, { Thead } from "../../components/Table";
import LoginAs from "../../components/layouts/LoginAs";

const MhsPojtShow = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const user = useRecoilValue(userState);
  const realTimeTahun = new Date().getFullYear();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => setTitle("Mahasiswa OJT"), [setTitle]);

  const [aktifOjt, setAktifOjt] = useState(false);

  const [anggotaOjt, setAnggotaOjt] = useState({});
  const [pojt, setPojt] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await showMhsPojt(user.id, token);
      
      setIsLoaded(true);
      if (res.data && res.data.anggota_ojt && res.data.pojt) {
        setAnggotaOjt(res.data.anggota_ojt);
        setPojt(res.data.pojt);
        setAktifOjt(true);
      } else if (res.data && res.data.anggota_ojt) {
        setAnggotaOjt(res.data.anggota_ojt);
        setAktifOjt(true);
      }
    } catch (err) {
      console.error(err.response);
    }
  }, [token, user.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = (mhs_id, mhs_nama) => {
    saConfirm(
      "warning",
      `Yakin pilih ${mhs_nama} sebagai kelompok anda ?`
    ).then(async (res) => {
      if (res.isConfirmed) {
        const res = await patchPojt(
          anggotaOjt.id,
          {
            pojt_id: pojt.id,
            mhs_id,
          },
          token
        );
        console.log(res.data);
        if (res.data === "success") {
          fetchData();
          saAlert("success", "Berhasil mengupdate data OJT");
        } else if (res.data === "fail") {
          saAlert(
            "warning",
            "Terjadi kesalah saat memilih kelompok",
            "Pastikan semua syarat pemilihan kelompok sesuai dengan catatan OJT di atas."
          );
        }
      }
    });
  };

  const handleSelectOjt = (pojt) => {
    saConfirm(
      "warning",
      `Pilih ${pojt.perusahaan?.nama} sebagai perusahaan untuk melaksanakan OJT ?`
    ).then(async (res) => {
      if (res.isConfirmed) {
        const res = await patchPojt(anggotaOjt.id,{ self_join: true, pojt_id: pojt.id },token);
        console.log(res.data);
        if (res.data.status === "success") {
          fetchData();
          fetchPerusahaan();
          saAlert("success", `Terima kasih sudah memilih ${pojt.perusahaan?.nama} sebagai perusahaan untuk melaksanakan OJT`);
        } else if (res.data.status === "fail") {
          saAlert(
            "warning",
            "Perusahaan ini sudah penuh",
            "Silahkan melakukan refresh ulang browser lalu pilih perusahaan lain yang tersedia."
          );
        }
      }
    });
  };

  const [btnPedoman, setBtnPedoman] = useState(false);

  const togglePedoman = () => {
    setBtnPedoman(!btnPedoman);
  };

  const [waktuSekarang, setWaktuSekarang] = useState(new Date());
  const waktuTarget = new Date('2024-02-22');

  useEffect(() => {
    const timerID = setInterval(() => prosesWaktu(), 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  const prosesWaktu = () => {
    setWaktuSekarang(new Date());
  };

  const [perusahaan, setPerusahaan] = useState({});

  const fetchPerusahaan = async () => {
    try {
      const res = await getPerusahaan("all", user.jurusan_id, token);
      setPerusahaan(res.data.pojt);
    } catch (error) {
      console.log(error.response);
    }
  }

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      if (!aktifOjt) {
        fetchPerusahaan();
      }
    }

    return () => {
      ignore = true;
    };
  }, [aktifOjt]);

  if (waktuSekarang >= waktuTarget) {
    if (isLoaded) {
      return (
        <>
          <div className="bg-white shadow rounded-3 mx-2 px-1 pt-3">
            <LoginAs />
  
            <div className="row">
              <div className="col-lg-12 mt-2 px-2 pt-2">

                {aktifOjt ? (
                  <>
                    {!pojt.id && (
                      <>
                        <div className="px-4">
                          <h5 className="mb-2 fw-semibold">Pendaftaran OJT dibuka</h5>
                          <p>Kini adik-adik dapat memilih secara langsung perusahaan sebagai tempat untuk melaksanakan On The Job Training (OJT) sesuai dengan keinginanmu. Berikut daftar perusahaan yang direkomendasikan untuk anda, Perlu diperhatikan beberapa hal sebagai berikut :</p>
                          <ul>
                            <li>Pastikan memilih perusahaan sesuai dengan keinginanmu.</li>
                            <li>Perusahaan yang sudah dipilih tidak dapat diubah kembali</li>
                            <li>Jika terjadi <span className="fw-medium text-danger">kendala</span>, silahkan menghubungi bagian <span className="fw-medium text-primary">PKK</span>.</li>
                          </ul>
                        </div>
                        <div className="row g-3 p-3">
                          {perusahaan.length > 0 && perusahaan.filter((p) => {

                            const checkSlot = p.pojt_jurusan && p.pojt_jurusan.length > 0 && p.pojt_jurusan.filter((pj) => pj.jurusan_id === user.jurusan_id).map((pj) => pj.jumlah_mhs);
                            const checkJoin = p.pojt_jurusan && p.pojt_jurusan.length > 0 && p.pojt_jurusan.filter((pj) => pj.jurusan_id === user.jurusan_id).map((pj) => p.mhs && p.mhs.length > 0 && p.mhs.filter((m) => m.jurusan_id === pj.jurusan_id).length);
                            const slot = checkSlot[0];
                            const join = checkJoin[0];
                            return slot > join;
                          }).map((p, index) => {
                            return (
                              <div key={index} className="col-lg-4">
                                <div className="border rounded-3" style={{ backgroundColor: '#f2f2f2' }}>
                                  <div className="p-3">
                                    <p className="mb-1 text-sm text-primary fw-medium">Nama Perusahaan :</p>
                                    <h6 className="fs-5 fw-semibold">{p.perusahaan?.nama ? p.perusahaan.nama : '-'}</h6>
                                    <p className="mb-1 text-sm text-primary fw-medium">alamat :</p>
                                    <h6 className="">{p.perusahaan?.alamat ? p.perusahaan.alamat : '-'}</h6>
                                    <p className="mb-1 text-sm text-primary fw-medium">Tersedia untuk :</p>
                                    {p.pojt_jurusan && p.pojt_jurusan.length > 0 ?
                                      p.pojt_jurusan.filter((pj) => pj.jurusan_id === user.jurusan_id).map((pj, index) => {
                                        return (
                                          <div key={index}>
                                            <p className="fs-5 fw-medium">{pj.jumlah_mhs} Mahasiswa</p>
                                            <h6>Sudah bergabung :</h6>
                                            <ul>
                                              {p.mhs && p.mhs.length > 0 ?
                                                p.mhs.filter((m) => m.jurusan_id === pj.jurusan_id).length > 0 ?
                                                  p.mhs.filter((m) => m.jurusan_id === pj.jurusan_id).map((m, index) => {
                                                    return (
                                                      <li key={index} className="border rounded px-2 mb-1" style={{ backgroundColor: '#ddd' }}>{m.nama}</li>
                                                    )
                                                  }) : (
                                                    <li>Belum ada mahasiswa.</li>
                                                  ) : (
                                                  <li>Belum ada mahasiswa.</li>
                                                )
                                              }
                                            </ul>
                                          </div>
                                        )
                                      })
                                      :
                                      <p>0 Mahasiswa</p>
                                    }
                                    <button onClick={() => handleSelectOjt(p)} className="btn btn-primary-3 w-100 mt-2" type="button">Pilih</button>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="nice-background rounded-3">
                    <div className="row g-0 d-flex justify-content-center custom-hue">
                      <div className="col-lg-6">
                        <div className="text-center p-4">
                          <i className="fa fa-fw fa-bullhorn text-white fs-3 mb-3 pt-5"></i>
                          <h5 className="text-white">Hai, Mahasiswa!</h5>
                          <p className="my-0 text-gray-500 small pb-4">
                            Saat ini anda tidak terdaftar sebagai mahasiswa OJT, mahasiswa akan otomatis terdaftar sebagai mahasiswa OJT pada saat masa OJT sudah dimulai.
                          </p>
                          <p className="my-0 text-gray-500 small pb-5">
                            Bila anda sudah memasuki masa OJT namun belum terdaftar sebagai mahasiswa OJT, silahkan hubungi bagian Akademik untuk mengaktifkan anda sebagai mahasiswa OJT.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="bg-gradient-primary rounded-3 p-0">
                  {pojt.id && (
                    <HeaderOjt
                      company={pojt.perusahaan ? pojt.perusahaan.nama : "-"}
                      dospem={anggotaOjt.dosen ? anggotaOjt.dosen.nama : "-"}
                      team={
                        anggotaOjt.kelompok_nim ? (
                          <>
                            {anggotaOjt.kelompok_nim
                              ? anggotaOjt.kelompok_nim
                              : "-"}
                          </>
                        ) : (
                          "Anda belum memiliki kelompok"
                        )
                      }
                    />
                  )}
  
                  {pojt.id && (
                    <div className="row g-0 pt-4">
                      <div className="col-lg-6">
                        <div className="px-4">
                          <h5 className="text-white fs-6">
                            <i className="fa fa-fw fa-triangle-exclamation text-warning" />{" "}
                            Catatan
                          </h5>
                          <ul>
                            <li className="text-gray-500 mb-2 small">
                              Diskusikan terlebih dahulu bersama teman yang ingin dijadikan kelompok
                            </li>
                            <li className="text-gray-500 mb-2 small">
                              Maksimal hanya terdapat 3 orang dalam satu kelompok
                            </li>
                            <li className="text-gray-500 mb-2 small">
                              Pastikan anda memilih kelompok sesuai dengan jenjang, jurusan, dan angkatan yang sama 
                            </li>
                            <li className="text-gray-500 mb-2 small">
                              Mohon berhati-hati dalam melakukan penginputan data OJT karena hanya dapat dilakukan sekali input dan tidak dapat diubah
                            </li>
                            <li className="text-gray-500 mb-2 small">
                              apabila terjadi kendala silahkan menghubungi bagian PKK
                            </li>
                          </ul>
                        </div>
                      </div>
  
                      <div className="col-lg-6">
                        <div className="px-4">
                          <h5 className="text-white fs-6">Keterangan</h5>
  
                          <table className="table table-sm">
                            <tbody>
                              <tr>
                                <td className="border-0 bg-none text-gray-500 text-nowrap">
                                  <li>
                                    <span className="text-success-light small fw-semibold">
                                      Anda
                                    </span>
                                  </li>
                                </td>
                                <td className="border-0 bg-none small text-gray-500">
                                  =
                                </td>
                                <td className="border-0 bg-none small text-gray-500">
                                  Data OJT anda
                                </td>
                              </tr>
                              <tr>
                                <td className="border-0 bg-none text-gray-500 text-nowrap">
                                  <li>
                                    <span className="text-info small fw-semibold">
                                      Kelompok anda
                                    </span>
                                  </li>
                                </td>
                                <td className="border-0 bg-none small text-gray-500">
                                  =
                                </td>
                                <td className="border-0 bg-none small text-gray-500">
                                  Sudah memiliki kelompok dan satu kelompok dengan
                                  anda
                                </td>
                              </tr>
                              <tr>
                                <td className="border-0 bg-none text-gray-500 text-nowrap">
                                  <li>
                                    <span className="text-warning small fw-semibold">
                                      Kelompok lain
                                    </span>
                                  </li>
                                </td>
                                <td className="border-0 bg-none small text-gray-500">
                                  =
                                </td>
                                <td className="border-0 bg-none small text-gray-500">
                                  Sudah memiliki kelompok namun tidak satu
                                  kelompok dengan anda
                                </td>
                              </tr>
                              <tr>
                                <td className="border-0 bg-none text-gray-500 text-nowrap">
                                  <li>
                                    <span className="text-danger-light small fw-semibold">
                                      Belum berkelompok
                                    </span>
                                  </li>
                                </td>
                                <td className="border-0 bg-none small text-gray-500">
                                  =
                                </td>
                                <td className="border-0 bg-none small text-gray-500">
                                  Belum punya kelompok
                                </td>
                              </tr>
                              <tr>
                                <td className="border-0 bg-none" colSpan={3}>
                                  <li className="small text-gray-500">
                                    Satu kelompok terdiri dari mahasiswa yang
                                    memiliki satu nim yang sama pada kolom
                                    kelompok
                                  </li>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
  
                      <div className="col-lg-12">
                        <div className="bg-light rounded-3" id="btnPedoman">
                          <div className="border-0 shadow">
                            <div className="bg-primary text-white text-center rounded shadow">
                              <button
                                className="w-100 border-0 py-2 btn-primary-3 rounded-3"
                                type="button"
                                // onClick={togglePedoman}
                                onClick={() => window.open('https://drive.google.com/drive/folders/14ZKd7BuPQwQz0Uc0urLBZbB-iePlD-5r?usp=sharing')}
                                aria-expanded="true"
                                aria-controls="btnPedoman"
                              >
                                <span className="d-flex justify-content-center align-items-center small">
                                  <i className="fa fa-fw fa-book-open me-2" />{" "}
                                  Pedoman OJT (Click to{" "}
                                  {btnPedoman ? "Hide" : "view"})
                                </span>
                              </button>
                            </div>
                            <div
                              id="btnPedoman"
                              className={`collapse-nice ${
                                btnPedoman ? "show-nice" : ""
                              }`}
                              data-bs-parent="#btnPedoman"
                            >
                              <div className="rounded-3 p-3">
                                <div className="row g-2">
                                  {aktifOjt ? (
                                    <>
                                      <h5 className="fs-6 fw-semibold text-center">
                                        Pedoman OJT digunakan dengan ketentuan
                                        sebagai berikut :
                                      </h5>
  
                                      <hr className="my-3" />
  
                                      <CardPedomanOjt
                                        title="PEDOMAN OJT LAPORAN PROYEK KHUSUS"
                                        text="Untuk mahasiswa Program 2 Tahun (D2) yang telah menyelesaikan semester 2."
                                      >
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                "/files/sia/ojt/PEDOMAN OJT LAPORAN PROYEK KHUSUS.pdf"
                                            )
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
  
                                      <CardPedomanOjt
                                        title="PEDOMAN OJT LAPORAN TUGAS AKHIR"
                                        text="Untuk mahasiswa Program 1 Tahun (D1) yang telah menyelesaikan semester 2."
                                      >
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                "/files/sia/ojt/PEDOMAN OJT LAPORAN TUGAS AKHIR.pdf"
                                            )
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
  
                                      <CardPedomanOjt
                                        title="PEDOMAN OJT TUGAS AKHIR"
                                        text="Untuk mahasiswa Program 2 Tahun (D2) yang telah menyelesaikan semester 4."
                                      >
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                "/files/sia/ojt/PEDOMAN OJT TUGAS AKHIR.pdf"
                                            )
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
                                      <CardPedomanOjt
                                        title="PEDOMAN OJT LAPORAN KEGIATAN KERJA"
                                        text="Untuk mahasiswa yang sudah bekerja (seluruh jenjang) dibuktikan dengan validasi perusahaan oleh PKK."
                                      >
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                "/files/sia/ojt/PEDOMAN LAPORAN KEGIATAN KERJA.pdf"
                                            )
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
                                      <CardPedomanOjt
                                        title="PEDOMAN OJT LAPORAN PROYEK INOVASI"
                                        text="Untuk mahasiswa yang sudah bekerja (seluruh jenjang) dibuktikan dengan validasi perusahaan oleh PKK namun bekerja tidak selinear dengan jurusan yang ditempuh di Alfa Prima."
                                      >
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                "/files/sia/ojt/PEDOMAN LAPORAN PROYEK INOVASI.pdf"
                                            )
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
                                      <CardPedomanOjt
                                        title="LOGBOOK"
                                        // text="Silahkan pilih salah satu dari file logbook berikut ini sesuai dengan kebutuhan anda (Hubungi akademik jika anda mengalami kendala)."
                                        text="Setelah klik tombol Open file anda akan diarahkan ke google drive yang berisi file logbook OJT & PKL, silahkan menyesuaikan file sesuai jurusan masing-masing"
                                      >
                                        {/* <h6 className="mb-0 mt-2 fw-semibold">
                                          Logbook OJT (PKL).
                                        </h6>
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                "/files/sia/ojt/logbook/8" +
                                                // user.jurusan_id +
                                                ".docx"
                                            )
                                          }
                                        >
                                          Open File
                                        </button> */}
  
                                        <h6 className="mb-0 mt-2 fw-semibold">
                                          Logbook OJT.
                                        </h6>
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          // onClick={() =>
                                          //   window.open(
                                          //     getOrigin() +
                                          //       "/files/sia/ojt/logbook_2/11" +
                                          //       // user.jurusan_id +
                                          //       ".docx"
                                          //   )
                                          // }
                                          onClick={() =>
                                            window.open('https://drive.google.com/drive/folders/1KcX4dNm24v2eotOivcAPNFLUKmTSDolX?usp=sharing')
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
                                      <CardPedomanOjt
                                        title="TATA TERTIB OJT"
                                        text="Tata tertib OJT."
                                      >
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                "/files/sia/ojt/TATA TERTIB OJT 2023.pdf"
                                            )
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
                                      <CardPedomanOjt
                                        title="FORM ABSENSI"
                                        text="Form Absensi."
                                      >
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                "/files/sia/ojt/FORM ABSENSI OJT.pdf"
                                            )
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
                                      <CardPedomanOjt
                                        title="FORM ASISTENSI"
                                        text="Form Asistensi."
                                      >
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                "/files/sia/ojt/FORM ASISTENSI.pdf"
                                            )
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
                                      <CardPedomanOjt
                                        title="FORM NILAI OJT"
                                        text="Form Nilai OJT."
                                      >
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                "/files/sia/ojt/FORM NILAI OJT.pdf"
                                            )
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
                                      <CardPedomanOjt
                                        title="FORM SURAT PERNYATAAN BEBAS PLAGIARISME"
                                        text="Form surat pernyataan bebas plagiarisme"
                                      >
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                "/files/sia/ojt/FORM SURAT PERNYATAAN BEBAS PLAGIARISME.docx"
                                            )
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
                                      <CardPedomanOjt
                                        title={`TIME TABLE PENYUSUNAN LAPORAN OJT ${realTimeTahun}`}
                                        text={`Time table penyususnan laporan ojt ${realTimeTahun}`}
                                      >
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                `/files/sia/ojt/${user.cabang.toLowerCase()}/time-table.xlsx`
                                            )
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
                                      <CardPedomanOjt
                                        title="FORM PERJANJIAN OJT LEBIH DARI TIGA BULAN"
                                        text="FORM PERJANJIAN OJT LEBIH DARI TIGA BULAN"
                                      >
                                        <button
                                          className="btn btn-sm btn-primary-3 w-100 mt-2"
                                          onClick={() =>
                                            window.open(
                                              getOrigin() +
                                                "/files/sia/ojt/FORM PERJANJIAN OJT LEBIH DARI TIGA BULAN.docx"
                                            )
                                          }
                                        >
                                          Open File
                                        </button>
                                      </CardPedomanOjt>
                                    </>
                                  ) : (
                                    <div className="text-center p-5">
                                      <i className="fa fa-fw fa-circle-exclamation text-secondary fs-3 mb-2 pt-5"></i>
                                      <p className="my-0 text-secondary small pb-5">
                                        Anda belum terdaftar sebagai mahasiswa
                                        aktif OJT, segera hubungi bagian akademik
                                        untuk mengaktifkan anda sebagai mahasiswa
                                        aktif OJT.
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
  
          <div className=" rounded-4 mx-3 p-3">
            <h1 className="fw-semibold text-center py-2 my-0 fs-5">Tabel Data</h1>
          </div>
  
          <div className="bg-white shadow rounded-3 mx-2 px-3">
            <div className="row g-4 rounded-4 row-size pt-0 pb-4">
              {pojt.mhs && pojt.mhs.length > 0 ? (
                pojt.mhs.map((m, index) => {
                  return (
                    <div className="col-lg-6" key={index}>
                      <div
                        className={`bg-gradient-primary rounded-3 shadow-nice p-4 h-100`}
                      >
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          {user.nim === m.nim ? (
                            <>
                              <div>
                                <h5 className="text-success-light fw-semibold fst-italic my-0">
                                  Anda
                                </h5>
                              </div>
                              <div className="bg-dark rounded-3 shadow-nice py-1 px-2">
                                <i className="fa fa-fw fa-user text-success-light"></i>
                              </div>
                            </>
                          ) : (
                            <>
                              {m.pivot &&
                                m.pivot.kelompok_nim &&
                                m.pivot.kelompok_nim ===
                                  anggotaOjt.kelompok_nim && (
                                  <>
                                    <div>
                                      <h5 className="text-info fw-semibold fst-italic my-0">
                                        Kelompok anda
                                      </h5>
                                    </div>
                                    <div className="bg-dark rounded-3 shadow-nice py-1 px-2">
                                      <i className="fa fa-fw fa-user-check text-success-light"></i>
                                    </div>
                                  </>
                                )}
                              {m.pivot &&
                                m.pivot.kelompok_nim &&
                                m.pivot.kelompok_nim !==
                                  anggotaOjt.kelompok_nim && (
                                  <>
                                    <div>
                                      <h5 className="text-warning fw-semibold fst-italic my-0">
                                        Kelompok lain
                                      </h5>
                                    </div>
                                    <div className="bg-dark rounded-3 shadow-nice py-1 px-2">
                                      <i className="fa fa-fw fa-user-slash text-warning"></i>
                                    </div>
                                  </>
                                )}
                              {m.pivot && !m.pivot.kelompok_nim && (
                                <>
                                  <div>
                                    <h5 className="text-danger-light fw-semibold fst-italic my-0">
                                      Belum berkelompok
                                    </h5>
                                  </div>
                                  <div className="bg-dark rounded-3 shadow-nice py-1 px-2">
                                    <i className="fa fa-fw fa-user-xmark text-danger-light"></i>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
  
                        <table className="table table-sm table-borderless">
                          <tbody>
                            <tr>
                              <td className="bg-none small text-gray-500">NIM</td>
                              <td className="bg-none small text-white">:</td>
                              <td className="bg-none small text-white">
                                {m.nim}
                              </td>
                            </tr>
                            <tr>
                              <td className="bg-none small text-gray-500">
                                Nama
                              </td>
                              <td className="bg-none small text-white">:</td>
                              <td className="bg-none small text-white">
                                {m.nama}
                              </td>
                            </tr>
                            <tr>
                              <td className="bg-none small text-gray-500">
                                Jurusan
                              </td>
                              <td className="bg-none small text-white">:</td>
                              <td className="bg-none small text-white">
                                {m.jurusan_ && m.jurusan_.nama}
                              </td>
                            </tr>
                            <tr>
                              <td className="bg-none small text-gray-500">
                                Jenjang
                              </td>
                              <td className="bg-none small text-white">:</td>
                              <td className="bg-none small text-white">
                                {m.jenjang}
                              </td>
                            </tr>
                            <tr>
                              <td className="bg-none small text-gray-500">
                                Kelompok
                              </td>
                              <td className="bg-none small text-white">:</td>
                              <td className="bg-none small text-white">
                                {m.pivot && m.pivot.kelompok_nim}
                              </td>
                            </tr>
                          </tbody>
                        </table>
  
                        {user.nim === m.nim ? (
                          <div className="w-100">
                            <span
                              className="rounded-1 btn-disable fw-semibold d-flex justify-content-center align-items-center"
                              href="#"
                            >
                              <i className="fa fa-fw fa-user me-1"></i>
                              Anda
                            </span>
                          </div>
                        ) : m.pivot &&
                          m.pivot.kelompok_nim &&
                          m.pivot.kelompok_nim === anggotaOjt.kelompok_nim ? (
                          <div className="w-100">
                            <span
                              className="rounded-1 btn-disable text-gray-500 text-sm d-flex justify-content-center align-items-center"
                              href="#"
                            >
                              <i className="fa fa-fw fa-user-check text-sm me-1"></i>
                              Berkelompok dengan anda
                            </span>
                          </div>
                        ) : m.pivot &&
                          m.pivot.kelompok_nim &&
                          m.pivot.kelompok_nim !== anggotaOjt.kelompok_nim ? (
                          <div className="w-100">
                            <span
                              className="rounded-1 btn-disable text-gray-500 text-sm d-flex justify-content-center align-items-center"
                              href="#"
                            >
                              <i className="fa fa-fw fa-user-check text-sm me-1"></i>
                              Sudah berkelompok
                            </span>
                          </div>
                        ) : (
                          m.pivot &&
                          !m.pivot.kelompok_nim && (
                            <div className="btn-group shadow w-100">
                              <button
                                className="btn btn-sm btn-primary-2 py-1"
                                onClick={() => handleSubmit(m.id, m.nama)}
                                type="button"
                              >
                                <i className="fa fa-fw fa-user-plus me-2"></i>
                                Pilih sebagai kelompok
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center p-5">
                  <i className="fa fa-fw fa-circle-exclamation text-secondary fs-3 mb-2 pt-5"></i>
                  <p className="my-0 text-secondary small pb-5">
                    Saat ini belum ada data On The Job Training.
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      );
    } else {
      return <Loading text="Loading. . ." />;
    }
  } else {
    return (
      <>
        <div className="nice-background rounded-3">
          <div className="row g-0 d-flex justify-content-center custom-hue">
            <div className="col-lg-6">
              <div className="text-center p-4">
                <i className="fa fa-fw fa-bullhorn text-white fs-3 mb-3 pt-5"></i>
                <h5 className="text-white">Informasi terkini terkait OJT</h5>
                <p className="my-0 text-gray-500 small pb-4">
                  Untuk mahasiswa yang akan memasuki masa OJT, saat ini system sedang melakukan proses memasukan dan penyesuaian data-data mahasiswa dengan tempat OJT. Tampilan halaman OJT akan dibuka kembali pada Tanggal <span className="text-warning">22 Februari 2024</span>, dimohon untuk para mahasiswa menunggu sesuai tanggal yang sudah ditentukan. tetap semangat ya!
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
};

export const HeaderOjt = (props) => {
  const { company, dospem, team } = props;

  return (
    <>
      <div className="score-background rounded-top-3 shadow-nice p-4">
        <div className="row g-0">
          <div className="col-lg-12 d-flex justify-content-start align-items-center">
            <div>
              <div>
                <h5 className="my-0 text-warning fst-italic fw-semibold fs-4">
                  {company}
                </h5>
              </div>

              <table className="table table-sm mb-2 mt-4">
                <tbody>
                  <tr>
                    <td className="border-0 bg-none small text-gray-500">
                      Nama Perusahaan
                    </td>
                    <td className="border-0 bg-none small text-white">:</td>
                    <td className="border-0 bg-none small text-white">
                      {company}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-0 bg-none small text-gray-500">
                      Dosen Pembimbing
                    </td>
                    <td className="border-0 bg-none small text-white">:</td>
                    <td className="border-0 bg-none small text-white">
                      {dospem}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-0 bg-none small text-gray-500">
                      Kelompok OJT anda
                    </td>
                    <td className="border-0 bg-none small text-white">:</td>
                    <td className="border-0 bg-none small text-white">
                      {team}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function CardPedomanOjt(props) {
  const { title, text, children } = props;
  return (
    <>
      <div className="col-lg-4 py-2">
        <div className="rounded-3 p-2 h-100">
          <h5 className="fs-6 fw-semibold">{title}</h5>
          <p className="my-0 small">{text}</p>
          {children}
        </div>
      </div>
    </>
  );
}

export default MhsPojtShow;