import React, { useCallback, useState } from 'react'
import { Modal, Table } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { tokenState, userState } from '../../storages/auth';
import { Tbody, Td, Th, Thead, Tr } from '../../components/Table';
import { getGrade, getHari } from '../../helpers';
import { showKelas } from '../../api';

function ModalShow({ id }) {

    const token = useRecoilValue(tokenState);
    const user = useRecoilValue(userState);
    const [modal, setModal] = useState(false);
    const [items, setItems] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const res = await showKelas(id, token);
            setItems(res.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [id, token]);

    const handleOpen = () => {
        fetchData();
        setModal(true);
    };
    
    const handleClose = () => {
        setModal(false);
        setItems({});
    };

    const getAbsensi = (pertemuan) => {
        let data =
            items.pengajaran &&
            items.pengajaran.length > 0 &&
            items.pengajaran
                .filter((p) => {
                    return (
                        String(p.pertemuan) === String(pertemuan) &&
                        String(p.status) !== "9"
                    );
                })
                .map((p) => {
                    return p.absensi
                        .filter((a) => {
                            return String(a.pertemuan) === String(pertemuan);
                        })
                        .map((a, index) => {
                            return (
                                <span key={index}>
                                    {String(a.status) === "1" && (
                                        <>
                                            <span className="text-success-light fw-semibold">H</span>
                                        </>
                                    )}
                                    {String(a.status) === "2" && (
                                        <>
                                            <span className="text-warning fw-semibold">I</span>
                                        </>
                                    )}
                                    {String(a.status) === "3" && (
                                        <>
                                            <span className="text-warning fw-semibold">S</span>
                                        </>
                                    )}
                                    {String(a.status) === "4" && (
                                        <>
                                            <span className="text-danger-light fw-semibold">A</span>
                                        </>
                                    )}
                                    {String(a.status) === "" && (
                                        <>
                                            <span className="text-gray-500 fw-semibold">-</span>
                                        </>
                                    )}
                                </span>
                            );
                        });
                });

        return data;
    };

    const getPersentasiAbsensi = () => {
        let data =
            items.pengajaran &&
            items.pengajaran.length > 0 &&
            (items.pengajaran.filter((p) => {
                return (
                    parseInt(p.status) !== 9 &&
                    String(p.pertemuan) !== "UTS" &&
                    String(p.pertemuan) !== "UAS" &&
                    p.absensi.filter(
                        (a) =>
                            parseInt(a.mhs_id) === parseInt(user.id) &&
                            parseInt(a.status) === 1
                    ).length > 0
                );
            }).length /
                12) *
            100;

        if (data) {
            return data.toFixed(2);
        } else return "";
    };

    const getNilai = (jenis) => {
        return (
            items.krs &&
            items.krs.length > 0 &&
            items.krs
                .filter((k) => parseInt(k.mhs_id) === parseInt(user.id))
                .map((k) => {
                    if (k.nilai && k.nilai[jenis]) {
                        return k.nilai[jenis];
                    } else return null;
                })
        );
    };

    return (
        <>
            <button onClick={handleOpen} className="btn btn-sm btn-primary-2"><i className="fa fa-fw fa-eye me-1"></i>Nilai & Absensi</button>

                <Modal
                    show={modal}
                    onHide={handleClose}
                    size="xl"
                    centered
                    contentClassName="border-0 rounded-5"
                >
                    <Modal.Body className='bg-gradient-primary rounded-4 p-0'>
                        <div className="score-background rounded-top-4 shadow-nice p-4">
                            <div>
                                <h5 className="my-0 text-warning fst-italic fw-semibold fs-3">
                                    {!loading ? items.kode : <div className='skeleton' style={{ width: '12rem', height: '24px' }} />}
                                </h5>
                            </div>

                            <div className="mb-2 mt-4">
                                <Table className="table-borderless">
                                    <Tbody>
                                        <Tr>
                                            <Td className="py-1 text-nowrap text-gray-500">Dosen</Td>
                                            <Td className="py-1 text-white">:</Td>
                                            <Td className="py-1 text-white">
                                                {!loading ? items.dosen && items.dosen.nama : <div className='skeleton' style={{ width: '12rem', height: '16px' }} />}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="py-1 text-nowrap text-gray-500">Ruangan</Td>
                                            <Td className="py-1 text-white">:</Td>
                                            <Td className="py-1 text-white">
                                                {!loading ? items.ruangan && items.ruangan.nama : <div className='skeleton' style={{ width: '8rem', height: '16px' }} />}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="py-1 text-nowrap text-gray-500">
                                                Hari & jam
                                            </Td>
                                            <Td className="py-1 text-white">:</Td>
                                            <Td className="py-1 text-white">
                                                {!loading ? items.jamkul &&
                                                    `${getHari(items.jamkul.hari)} / ${items.jamkul.dari
                                                    }-${items.jamkul.sampai}`
                                                    :
                                                    <div className='skeleton' style={{ width: '10rem', height: '16px' }} />
                                                }
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td className="py-1 text-nowrap text-gray-500">Group WA</Td>
                                            <Td className="py-1 text-white">:</Td>
                                            <Td className="py-1 text-white">
                                                {!loading ? (
                                                    <button
                                                        className="btn btn-sm btn-success-2 shadow"
                                                        onClick={() => {
                                                            window.open(items.group_whatsapp);
                                                        }}
                                                    >
                                                        <i className="bi bi-whatsapp me-1"></i> Join group
                                                    </button>
                                                ) : (
                                                    <div className='skeleton' style={{ width: '6rem', height: '26px' }} />
                                                )}
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="row pt-4 g-0">
                            <div className="col-lg-6">
                                <div className="px-4">
                                    <h5 className="text-white fs-6">Absensi</h5>

                                    <div className="table-responsive">
                                        <Table className="table-striped table-bordered border-secondary">
                                            <Thead>
                                                <Tr>
                                                    <Th className="px-2">Pertemuan</Th>
                                                    <Th>Keterangan</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <Tr>
                                                    <Td className="text-white text-center">1</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("1") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">2</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("2") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">3</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("3") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">4</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("4") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">5</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("5") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">6</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("6") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">UTS</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("UTS") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">7</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("7") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">8</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("8") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">9</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("9") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">10</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("10") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">11</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("11") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">12</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("12") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-white text-center">UAS</Td>
                                                    <Td className="text-center py-0">{!loading ? getAbsensi("UAS") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                            </Tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="px-4">
                                    <h5 className="text-white fs-6">Nilai</h5>

                                    <div className="table-responsive">
                                        <Table className="table-striped table-bordered border-secondary">
                                            <Thead>
                                                <Tr>
                                                    <Th>Keterangan</Th>
                                                    <Th className="px-4">Nilai</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <Tr>
                                                    <Td className="text-gray-500 text-center">Absensi</Td>
                                                    <Td className="text-white text-center">{!loading ? getPersentasiAbsensi() : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-gray-500 text-center">Tugas</Td>
                                                    <Td className="text-white text-center">{!loading ? getNilai("tugas") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-gray-500 text-center">UTS</Td>
                                                    <Td className="text-white text-center">{!loading ? getNilai("uts") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td className="text-gray-500 text-center">UAS</Td>
                                                    <Td className="text-white text-center">{!loading ? getNilai("uas") : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}</Td>
                                                </Tr>
                                                    <Tr>
                                                        <Td className="text-gray-500 text-center">Total</Td>
                                                        <Td className="text-white text-center">
                                                            {!loading ? items.krs && items.krs
                                                                .filter((k) => k.nilai)
                                                                .map((k) => {
                                                                    return k.nilai.total;
                                                                }) : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-gray-500 text-center">Predikat</Td>
                                                        <Td className="text-warning fw-semibold text-center">
                                                            {!loading ? items.krs && getGrade(
                                                                parseInt(
                                                                    items.krs
                                                                        .filter((k) => k.nilai)
                                                                        .map((k) => {
                                                                            return k.nilai.grade;
                                                                        })
                                                                )
                                                            ) : <div className='skeleton align-middle' style={{ width: 'auto', height: '18px' }} />}
                                                        </Td>
                                                    </Tr>
                                            </Tbody>
                                        </Table>
                                    </div>

                                    <h5 className="text-white fs-6">Catatan</h5>
                                    <ul>
                                        <li className="text-gray-500 mb-2 text-sm">
                                            Nilai sebenarnya dari absensi akan tampil apabila
                                            semua pertemuan sudah dilakukan.
                                        </li>
                                        <li className="text-gray-500 mb-2 text-sm">
                                            Nilai{" "}
                                            <span className="text-warning text-sm">Tugas</span> ,{" "}
                                            <span className="text-warning text-sm">UTS</span> ,
                                            dan <span className="text-warning text-sm">UAS</span>{" "}
                                            akan tampil apabila dosen yang mengajar sudah
                                            menginputkan kedalam sistem.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row g-0 pb-3">
                                <div className="col-lg-4">
                                    <div className="px-4">
                                        <h5 className="text-white fs-6 mt-2">
                                            Keterangan absensi
                                        </h5>

                                        <div className="table-responsive">
                                            <Table className="table-striped table-bordered border-secondary">
                                                <Tbody>
                                                    <Tr>
                                                        <Td className="text-success-light fw-semibold text-center">
                                                            H
                                                        </Td>
                                                        <Td className="text-gray-500 text-center">
                                                            Hadir
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-warning fw-semibold text-center">
                                                            I
                                                        </Td>
                                                        <Td className="text-gray-500 text-center">
                                                            Ijin
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-warning fw-semibold text-center">
                                                            S
                                                        </Td>
                                                        <Td className="text-gray-500 text-center">
                                                            Sakit
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-danger-light fw-semibold text-center">
                                                            A
                                                        </Td>
                                                        <Td className="text-gray-500 text-center">
                                                            Alpa
                                                        </Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="px-4">
                                        <h5 className="text-white fs-6 mt-2">
                                            Keterangan nilai
                                        </h5>

                                        <div className="table-responsive">
                                            <Table className="table-striped table-bordered border-secondary">
                                                <Tbody>
                                                    <Tr>
                                                        <Td className="text-gray-500 text-center">
                                                            85 s/d 100
                                                        </Td>
                                                        <Td className="text-warning fw-semibold text-center">
                                                            A
                                                        </Td>
                                                        <Td className="text-gray-500">
                                                            Sangat Memuaskan
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-gray-500 text-center">{`75 s/d <85`}</Td>
                                                        <Td className="text-warning fw-semibold text-center">
                                                            B
                                                        </Td>
                                                        <Td className="text-gray-500">Memuaskan</Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-gray-500 text-center">{`65 s/d <75`}</Td>
                                                        <Td className="text-warning fw-semibold text-center">
                                                            C
                                                        </Td>
                                                        <Td className="text-gray-500">Cukup</Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-gray-500 text-center">{`45 s/d <65`}</Td>
                                                        <Td className="text-warning fw-semibold text-center">
                                                            D
                                                        </Td>
                                                        <Td className="text-gray-500">
                                                            Kurang Memuaskan
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-gray-500 text-center">{`0 s/d <45`}</Td>
                                                        <Td className="text-warning fw-semibold text-center">
                                                            E
                                                        </Td>
                                                        <Td className="text-gray-500">Sangat Kurang</Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="px-4">
                                        <h5 className="text-white fs-6 mt-2">Bobot nilai</h5>

                                        <div className="table-responsive">
                                            <Table className="table-striped table-bordered border-secondary">
                                                <Tbody>
                                                    <Tr>
                                                        <Td className="text-gray-500 text-center">
                                                            Nilai Absensi
                                                        </Td>
                                                        <Td className="text-white text-center">
                                                            {items.persentasi_kehadiran ? `${items.persentasi_kehadiran}%` : '-'}
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-gray-500 text-center">
                                                            Nilai Tugas
                                                        </Td>
                                                        <Td className="text-white text-center">
                                                            {items.persentasi_tugas ? `${items.persentasi_tugas}%` : '-'}
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-gray-500 text-center">
                                                            UTS
                                                        </Td>
                                                        <Td className="text-white text-center">
                                                            {items.persentasi_uts ? `${items.persentasi_uts}%` : '-'}
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-gray-500 text-center">
                                                            UAS
                                                        </Td>
                                                        <Td className="text-white text-center">
                                                            {items.persentasi_uas ? `${items.persentasi_uas}%` : '-'}
                                                        </Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-group mobile-justify-center px-4 pb-4">
                            <button type="button" onClick={handleClose} className="btn btn-sm btn-dark">
                                Kembali
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
       </>
    )
}

export default ModalShow