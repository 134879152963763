import React, { useCallback, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { tokenState } from '../../storages/auth';
import { saAlert } from '../../helpers';
import { postPenilaianDosen, showKelas } from '../../api';
import Radio from '../../components/Radio';
import Textarea from '../../components/Textarea';

function ModalPenilaianDosen({ kelasId }) {

    const token = useRecoilValue(tokenState);
    const [items, setItems] = useState({});
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const fetchDataKelas = useCallback(async () => {
        setLoading(true);
        try {
            const res = await showKelas(kelasId, token);
            if (res.data) {
                setItems(res.data);

                if (res.data.penilaian_dosen) {
                    setForm(res.data.penilaian_dosen[0]);
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [kelasId, token]);

    const handleOpen = () => {
        fetchDataKelas();
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
        setForm({});
        setErrors({});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postPenilaianDosen({ ...form, kelas_id: items.id }, token, `add_row=true`)
            .then((res) => {
                if (res.data === "success") {
                    handleClose();
                    fetchDataKelas();
                    saAlert(
                        "success",
                        `Berhasil mengisi nilai Dosen kelas ${items.kode}`
                    );
                }
            })
            .catch((err) => {
                if (err.response.status === 422) {
                    setErrors(err.response.data.errors);
                }
            });
    };

    return (
        <>
            <button onClick={handleOpen} className="btn btn-sm btn-warning-2"><i className="fa fa-fw fa-star me-1"></i>Review dosen</button>

            <Modal
                show={modal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="xl"
                centered
                contentClassName="border-0 rounded-5"
            >
                <Modal.Body className='bg-gradient-primary rounded-4 p-0'>
                    <div className="score-background rounded-top-3 shadow-nice p-4">
                        <div>
                            <p className="my-0 text-warning text-sm">Penilaian Kepada :</p>
                            <h5 className="my-0 text-white">
                                {!loading ? items.dosen && items.dosen.nama : <div className='skeleton' style={{width: '16rem', height: '24px'}} />}
                            </h5>
                            <p className="my-0 text-warning text-sm">Kelas :</p>
                            <h5 className="my-0 text-white">
                                {!loading ? items.kode : <div className='skeleton' style={{width: '8rem', height: '24px'}} />}
                            </h5>
                        </div>
                    </div>

                    <div className="row pt-4 g-0">
                        <div className="col-lg-12">
                            <div className="px-4">
                                <h5 className="text-white fs-6">Note</h5>
                                <ul>
                                    <li className="text-gray-500 mb-2 small">
                                        Harap memastikan bahwa pengisian nilai dilakukan dengan penuh kejujuran dan ketulusan hati.
                                    </li>
                                    <li className="text-gray-500 mb-2 small">
                                        Nilai hanya dapat diisi{" "}
                                        <span className="text-warning text-sm">1 kali</span>,
                                        dan tidak dapat diubah.
                                    </li>
                                    <li className="text-gray-500 mb-2 small">
                                        Perlu diingat bahwa dalam memberikan kritik dan penilaian, harus senantiasa menjaga kesopanan serta etika yang baik.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="px-4">
                                <h5 className="text-white fs-6">Nilai dosen</h5>

                                <div className="table-responsive">
                                    <table className="table table-sm table-striped table-bordered border-secondary">
                                        <thead>
                                            <tr>
                                                <th className="text-nowrap text-center text-warning fw-semibold bg-primary small">
                                                    Penilaian
                                                </th>
                                                <th className="text-nowrap text-center text-warning fw-semibold bg-primary small">
                                                    Nilai
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="align-middle text-center text-gray-300 bg-none text-sm p-2">
                                                    Cara berpenampilan
                                                </td>
                                                <td className="align-middle bg-none text-white p-2">
                                                    <ul className="list-unstyled my-0">
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Tidak Memuaskan"
                                                                value="1"
                                                                name="penampilan"
                                                                id="penampilan_1"
                                                                checked={
                                                                    form && form.penampilan && form.penampilan
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Kurang Memuaskan"
                                                                value="2"
                                                                name="penampilan"
                                                                id="penampilan_2"
                                                                checked={
                                                                    form && form.penampilan && form.penampilan
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Memuaskan"
                                                                value="3"
                                                                name="penampilan"
                                                                id="penampilan_3"
                                                                checked={
                                                                    form && form.penampilan && form.penampilan
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Sangat Memuaskan"
                                                                value="4"
                                                                name="penampilan"
                                                                id="penampilan_4"
                                                                checked={
                                                                    form && form.penampilan && form.penampilan
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            {errors.penampilan && (
                                                                <p className="text-sm text-danger m-0">
                                                                    Penampilan tidak boleh kosong !
                                                                </p>
                                                            )}
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle text-center text-gray-300 bg-none text-sm p-2">
                                                    Kepribadian secara umum
                                                </td>
                                                <td className="align-middle bg-none text-white p-2">
                                                    <ul className="list-unstyled my-0">
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Tidak Memuaskan"
                                                                value="1"
                                                                name="kepribadian"
                                                                id="kepribadian_1"
                                                                checked={
                                                                    form &&
                                                                    form.kepribadian &&
                                                                    form.kepribadian
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Kurang Memuaskan"
                                                                value="2"
                                                                name="kepribadian"
                                                                id="kepribadian_2"
                                                                checked={
                                                                    form &&
                                                                    form.kepribadian &&
                                                                    form.kepribadian
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Memuaskan"
                                                                value="3"
                                                                name="kepribadian"
                                                                id="kepribadian_3"
                                                                checked={
                                                                    form &&
                                                                    form.kepribadian &&
                                                                    form.kepribadian
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Sangat Memuaskan"
                                                                value="4"
                                                                name="kepribadian"
                                                                id="kepribadian_4"
                                                                checked={
                                                                    form &&
                                                                    form.kepribadian &&
                                                                    form.kepribadian
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            {errors.kepribadian && (
                                                                <p className="text-sm text-danger m-0">
                                                                    Kepribadian tidak boleh kosong !
                                                                </p>
                                                            )}
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle text-center text-gray-300 bg-none text-sm p-2">
                                                    Penguasaan penyampaian materi
                                                </td>
                                                <td className="align-middle bg-none text-white p-2">
                                                    <ul className="list-unstyled my-0">
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Tidak Memuaskan"
                                                                value="1"
                                                                name="penguasaan_materi"
                                                                id="penguasaan_materi_1"
                                                                checked={
                                                                    form &&
                                                                    form.penguasaan_materi &&
                                                                    form.penguasaan_materi
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Kurang Memuaskan"
                                                                value="2"
                                                                name="penguasaan_materi"
                                                                id="penguasaan_materi_2"
                                                                checked={
                                                                    form &&
                                                                    form.penguasaan_materi &&
                                                                    form.penguasaan_materi
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Memuaskan"
                                                                value="3"
                                                                name="penguasaan_materi"
                                                                id="penguasaan_materi_3"
                                                                checked={
                                                                    form &&
                                                                    form.penguasaan_materi &&
                                                                    form.penguasaan_materi
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Sangat Memuaskan"
                                                                value="4"
                                                                name="penguasaan_materi"
                                                                id="penguasaan_materi_4"
                                                                checked={
                                                                    form &&
                                                                    form.penguasaan_materi &&
                                                                    form.penguasaan_materi
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            {errors.penguasaan_materi && (
                                                                <p className="text-sm text-danger m-0">
                                                                    Penguasaan Materi tidak boleh kosong !
                                                                </p>
                                                            )}
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle text-center text-gray-300 bg-none text-sm p-2">
                                                    Kemudahan penerimaan materi
                                                </td>
                                                <td className="align-middle bg-none text-white p-2">
                                                    <ul className="list-unstyled my-0">
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Tidak Memuaskan"
                                                                value="1"
                                                                name="mudah_diterima"
                                                                id="mudah_diterima_1"
                                                                checked={
                                                                    form &&
                                                                    form.mudah_diterima &&
                                                                    form.mudah_diterima
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Kurang Memuaskan"
                                                                value="2"
                                                                name="mudah_diterima"
                                                                id="mudah_diterima_2"
                                                                checked={
                                                                    form &&
                                                                    form.mudah_diterima &&
                                                                    form.mudah_diterima
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Memuaskan"
                                                                value="3"
                                                                name="mudah_diterima"
                                                                id="mudah_diterima_3"
                                                                checked={
                                                                    form &&
                                                                    form.mudah_diterima &&
                                                                    form.mudah_diterima
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Sangat Memuaskan"
                                                                value="4"
                                                                name="mudah_diterima"
                                                                id="mudah_diterima_4"
                                                                checked={
                                                                    form &&
                                                                    form.mudah_diterima &&
                                                                    form.mudah_diterima
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            {errors.mudah_diterima && (
                                                                <p className="text-sm text-danger m-0">
                                                                    Mudah Diterima tidak boleh kosong !
                                                                </p>
                                                            )}
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle text-center text-gray-300 bg-none text-sm p-2">
                                                    Semangat dalam mengajar
                                                </td>
                                                <td className="align-middle bg-none text-white p-2">
                                                    <ul className="list-unstyled my-0">
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Tidak Memuaskan"
                                                                value="1"
                                                                name="semangat_mengajar"
                                                                id="semangat_mengajar_1"
                                                                checked={
                                                                    form &&
                                                                    form.semangat_mengajar &&
                                                                    form.semangat_mengajar
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Kurang Memuaskan"
                                                                value="2"
                                                                name="semangat_mengajar"
                                                                id="semangat_mengajar_2"
                                                                checked={
                                                                    form &&
                                                                    form.semangat_mengajar &&
                                                                    form.semangat_mengajar
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Memuaskan"
                                                                value="3"
                                                                name="semangat_mengajar"
                                                                id="semangat_mengajar_3"
                                                                checked={
                                                                    form &&
                                                                    form.semangat_mengajar &&
                                                                    form.semangat_mengajar
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Sangat Memuaskan"
                                                                value="4"
                                                                name="semangat_mengajar"
                                                                id="semangat_mengajar_4"
                                                                checked={
                                                                    form &&
                                                                    form.semangat_mengajar &&
                                                                    form.semangat_mengajar
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            {errors.semangat_mengajar && (
                                                                <p className="text-sm text-danger m-0">
                                                                    Semangat Mengajar tidak boleh kosong !
                                                                </p>
                                                            )}
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle text-center text-gray-300 bg-none text-sm p-2">
                                                    Ketepatan waktu mengajar
                                                </td>
                                                <td className="align-middle bg-none text-white p-2">
                                                    <ul className="list-unstyled my-0">
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Tidak Memuaskan"
                                                                value="1"
                                                                name="ketepatan_waktu"
                                                                id="ketepatan_waktu_1"
                                                                checked={
                                                                    form &&
                                                                    form.ketepatan_waktu &&
                                                                    form.ketepatan_waktu
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Kurang Memuaskan"
                                                                value="2"
                                                                name="ketepatan_waktu"
                                                                id="ketepatan_waktu_2"
                                                                checked={
                                                                    form &&
                                                                    form.ketepatan_waktu &&
                                                                    form.ketepatan_waktu
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Memuaskan"
                                                                value="3"
                                                                name="ketepatan_waktu"
                                                                id="ketepatan_waktu_3"
                                                                checked={
                                                                    form &&
                                                                    form.ketepatan_waktu &&
                                                                    form.ketepatan_waktu
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            <Radio
                                                                inline={true}
                                                                label="Sangat Memuaskan"
                                                                value="4"
                                                                name="ketepatan_waktu"
                                                                id="ketepatan_waktu_4"
                                                                checked={
                                                                    form &&
                                                                    form.ketepatan_waktu &&
                                                                    form.ketepatan_waktu
                                                                }
                                                                onChange={(e) =>
                                                                    setForm((prevState) => {
                                                                        return {
                                                                            ...prevState,
                                                                            [e.target.name]: e.target.value,
                                                                        };
                                                                    })
                                                                }
                                                            />
                                                        </li>
                                                        <li>
                                                            {errors.ketepatan_waktu && (
                                                                <p className="text-sm text-danger m-0">
                                                                    Ketepatan Waktu tidak boleh kosong !
                                                                </p>
                                                            )}
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="px-4">
                                <Textarea
                                    name="kritik_saran"
                                    placeholder="Kritik dan Saran anda . . . ."
                                    value={form && form.kritik_saran && form.kritik_saran}
                                    onChange={(e) =>
                                        setForm((prevState) => {
                                            return {
                                                ...prevState,
                                                [e.target.name]: e.target.value,
                                            };
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="btn-group mobile-justify-center px-4 pb-4">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary-2"
                            onClick={(e) => {
                                if (
                                    items.penilaian_dosen &&
                                    items.penilaian_dosen.length > 0
                                ) {
                                    saAlert(
                                        "warning",
                                        "Nilai yang sudah terisi tidak dapat diubah !"
                                    );
                                } else {
                                    handleSubmit(e);
                                }
                            }}
                        >
                            Simpan
                        </button>
                        <button type="button" onClick={handleClose} className="btn btn-sm btn-dark">
                            Kembali
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalPenilaianDosen