import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { postLogout } from "../../api";
import { getOrigin, saAlert, saConfirm } from "../../helpers";
import { semesterAjaranState, tahunAjaranState } from "../../storages";
import { tokenState } from "../../storages/auth";

const LayoutsTopbar = () => {
  const [token, setToken] = useRecoilState(tokenState);
  const setUser = useSetRecoilState(tokenState);
  const setTahunAjaran = useSetRecoilState(tahunAjaranState);
  const setSemesterAjaran = useSetRecoilState(semesterAjaranState);

  const handleLogout = () => {
    saConfirm("warning", "Yakin ingin logout ?").then((res) => {
      if (res.isConfirmed) {
        postLogout(token).then((res) => {
          if (res.data === "success") {
            setToken("");
            setUser({});
            setTahunAjaran("");
            setSemesterAjaran("");
            localStorage.removeItem("_tokenMhs");
            saAlert("success", "Berhasil logout !");
          }
        });
      }
    });
  };

  return (
    <div>
      <nav className="navbar bg-primary shadow-nice rounded-3 m-1 fixed-top">
        <div className="container-fluid">
            <div>
                <Link className="navbar-brand text-white d-flex align-items-center p-0" to='/'>
                    <img src="/img/logo/AP-W.png" alt="" width={36} height={36}/>
                    <span className='fs-5'>SIA - ALFA PRIMA</span>
                </Link>
            </div>

            <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                <span className="fa fa-fw fa-bars link-warning py-1 fs-3" />
            </button>

            <div className="offcanvas offcanvas-end bg-light shadow-nice rounded-3 border-0" data-bs-scroll="false" data-bs-backdrop="static" tabIndex={-1} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header bg-primary rounded-top-3 shadow-xl d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <img src="/img/logo/AP-W.png" alt="" width={36} height={36}/>
                        <h5 className="offcanvas-title text-white fs-5" id="offcanvasNavbarLabel">SIA - ALFA PRIMA</h5>
                    </div>
                    <i className='fa fa-fw fa-close text-gray-400 fs-4 shadow-nice rounded-2 p-1' type="button" data-bs-dismiss="offcanvas" />
                </div>
                <div className="offcanvas-body">
                    <ul className="navbar-nav justify-content-end flex-grow-1">
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                            <Link className="nav-link border-bottom link-primary" to="/"><i className='fa fa-fw fa-circle-user fs-5 me-2' /> Profile</Link>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                            <Link className="nav-link border-bottom link-primary" to="/krs"><i className='fa fa-fw fa-graduation-cap fs-5 me-2' /> Jadwal Perkuliahan<span className="bg-warning text-black fw-medium px-2 rounded ms-1 text-sm">Update UI</span></Link>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                            <Link className="nav-link border-bottom link-primary" to="/pembayaran"><i className='fa fa-fw fa-sack-dollar fs-5 me-2' /> Pembayaran</Link>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                            <Link className="nav-link border-bottom link-primary" to="/progkam"><i className='fa fa-fw fa-medal fs-5 me-2' /> Nilai TAK</Link>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                            <Link className="nav-link border-bottom link-primary" to="/buku-saku"><i className='fa fa-fw fa-address-book fs-5 me-2' /> Buku Saku</Link>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                            <Link className="nav-link border-bottom link-primary" to="/mhs-pojt-show"><i className='fa fa-fw fa-users fs-5 me-2' /> OJT<span className="bg-warning text-black fw-medium px-2 rounded ms-1 text-sm">Update Fitur</span></Link>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                            <Link className="nav-link border-bottom link-primary" to="/berita-terkini"><i className='fa fa-fw fa-border-all fs-5 me-2' /><span>Berita Terkini</span></Link>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                            <Link className="nav-link border-bottom link-primary" to="/buku-perpustakaan"><i className='fa fa-fw fa-book-open fs-5 me-2' /> Buku Perpustakaan</Link>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                            <Link className="nav-link border-bottom link-primary" to="/ks-create"><i className='fa fa-fw fa-star fs-5 me-2' /> Kritik & Saran</Link>
                        </li>
                        {/* <li className="nav-item" data-bs-dismiss="offcanvas">
                            <Link className="nav-link border-bottom link-primary" to="/survey"><i className='fa fa-fw fa-square-poll-vertical fs-5 me-2' /><span>Survey</span><span className="bg-warning text-black fw-semibold px-2 rounded ms-1 text-sm">Baru</span></Link>
                        </li> */}
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                            <Link className="nav-link border-bottom link-primary" to="/peminjaman-ruangan"><i className='fa fa-fw fa-house fs-5 me-2' /> Peminjaman Ruangan</Link>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                            <Link to='#' className="nav-link btn btn-sm btn-primary-3 border-0 rounded-3 shadow mt-3 w-100" onClick={handleLogout}><i className='align-middle fa fa-power-off me-1' /> Logout</Link>
                        </li>
                        <div className="mt-3">
                            <p className="mb-2">
                                <i className="fa fa-circle-question text-info me-2"/>Untuk informasi lebih lengkap tentang cara penggunaan SIA,{" "}
                                <button 
                                    className="bg-none border-0 border-bottom border-primary p-0 m-0 text-primary fw-semibold"
                                    type="button"
                                    onClick={() =>
                                    window.open(
                                        getOrigin() +
                                        "/storage/akademik/tutorial-sia.pdf"
                                    )
                                    }
                                >
                                    Klik disini
                                </button>
                            </p>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
    </div>
  );
};

export default LayoutsTopbar;
